

















import Vue from 'vue';
import axios from 'axios';
import QuizStatus from '@/models/QuizStatus';
// eslint-disable-next-line import/no-cycle
import QuizUtils from '@/libs/QuizUtils';

export default Vue.extend({
  data() {
    return {
      iniData: [
        { x: 'TITLE', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
        { x: 'D', y: [0, 0] },
      ],
      series: [
        {
          data: [
            { x: 'Title', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
            { x: 'D', y: [0, 0] },
          ],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'rangeBar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: 'datetime',
        },
      },
      code: '',
      currentNum: 0,
      list: '',
    };
  },
  computed: {
    // quizCode(): string | null {
    //   return this.$store.state.code;
    // },
  },
  created() {
    this.code = this.$route.query.shortId as string;
    axios
      .get(`https://eye-tracking-api.qsticker.net/v1/tracking/analysis?shortId=${this.code}`)
      .then((res) => {
        console.log(res.data);
        this.list = res.data.list;
        const tempChartData = this.iniData;
        res.data.list[this.currentNum].sequence.forEach((element : any) => {
          tempChartData.push({ x: element[0], y: [element[1], element[2]] });
        });
        this.series = [{ data: tempChartData }];
      });
  },
  methods: {
    // this.series = [{
    //       data: newData
    //     }]
    refreshChart() {
      this.series = [{ data: [] }];
      setTimeout(() => {
        console.log();
      }, 100);
      const tempChartData = [
        { x: 'TITLE', y: [0, 0] }, { x: 'A', y: [0, 0] }, { x: 'B', y: [0, 0] }, { x: 'C', y: [0, 0] },
        { x: 'D', y: [0, 0] },
      ];
      (JSON.parse(JSON.stringify(this.list[this.currentNum]))).sequence.forEach((element : any) => {
        tempChartData.push({ x: element[0], y: [element[1], element[2]] });
      });
      this.series = [{ data: tempChartData }];
    },
    preBtnClicked() {
      if (this.currentNum > 0) {
        this.currentNum -= 1;
        this.refreshChart();
      }
    },
    nextBtnClicked() {
      if (this.currentNum < this.list.length - 1) {
        this.currentNum += 1;
        this.refreshChart();
      }
    },
  },
});
