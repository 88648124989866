export interface QuestionModel {
    userAnswer: String[];
    sourceQuiz: unknown;
    uuid: string;
    timeSpent: number;
    multipleSelect: boolean;
    isBlankFill: boolean;
    blankFillAnswer: string;
    answerRequired: boolean;
}

export class Question implements QuestionModel {
    userAnswer = [] as String[];

    sourceQuiz = {} as string | JSON;

    uuid = '';

    timeSpent = 0;

    multipleSelect = false;

    isBlankFill = false;

    blankFillAnswer = '';

    answerRequired = false;

    constructor(userAnswer: [], sourceQuiz: string | JSON, uuid: string, timeSpent: number,
      multipleSelect: boolean, isBlankFill: boolean, blankFillAnswer: string, answerRequired: boolean) {
      if (userAnswer === null || userAnswer === undefined) {
        this.userAnswer = [];
      } else {
        this.userAnswer = userAnswer;
      }
      this.sourceQuiz = sourceQuiz;
      this.uuid = uuid;
      this.timeSpent = timeSpent;
      this.multipleSelect = multipleSelect;
      this.isBlankFill = isBlankFill;
      this.blankFillAnswer = blankFillAnswer;
      this.answerRequired = answerRequired;
    }
}
