
































































































































































































import Vue from 'vue';
import { ToastPlugin } from 'bootstrap-vue';
import liff from '@line/liff';
import Stage from '@/components/stage/Stage.vue'; // @ is an alias to /src
import QuestionList from '@/components/QuestionList.vue';
import { Question } from '@/models/Question';
import VueAudio from '@/components/VueAudio.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
// eslint-disable-next-line import/no-cycle
import QuizApi from '@/apis/QuizApi';
// eslint-disable-next-line import/no-cycle
import UserApi from '@/apis/UserApi';
import LoggingApi from '@/apis/LoggingApi';
import ExampleQuizSet from '@/models/ExampleQuizSet';
import { CreateRespondModel } from '@/apis/models/CreateModel';
import Status from '@/apis/models/RespondStatus';
import QuizStatus from '@/models/QuizStatus';
import i18n from '@/i18n';
import OnboardingStatus from '@/models/OnboardingStatus';
// eslint-disable-next-line import/no-cycle
import QuizUtils from '@/libs/QuizUtils';

Vue.use(ToastPlugin);

export default Vue.extend({
  components: {
    Stage,
    QuestionList,
    VueAudio,
    LanguageSelector,
    ConfirmModal,
  },
  data() {
    return {
      currentNum: 0,
      amount: 0,
      audioUrl: '',
      quizData: [] as any[],
      errored: false,
      loading: true,
      modalShow: false,
      counter: 0,
      toastContent: '',
      ansSet: [] as Question[],
      timer: null as any,
      tempSubmitTimer: null as any,
      isAllSelected: false,
      allowLastTip: true,
      allowFirstTip: true,
      allowAllSelectedTip: true,
      Status,
      QuizStatus,
      clientType: '',
      audioControl: false as boolean,
      onboardingStatus: OnboardingStatus.DISABLE, // 'DISABLE', 'INITIAL', 'MANUAL'
      onboardingIndex: 0 as number,
      onboardingPrevIndex: 0 as number,
      onboardingDataList: [
        {
          title: `${i18n.t('使用教學')} 1 / 4`,
          content: `${i18n.t('點選您認為正確的答案。')}`,
          target: 'onboarding-1',
          hasNextBtn: true,
          hasPrevBtn: false,
          hasSkipBtn: true,
          hasCloseBtn: false,
        },
        {
          title: `${i18n.t('使用教學')} 2 / 4`,
          content: `${i18n.t('完成一題作答後，點選「下一題」繼續測驗。')}`,
          target: 'onboarding-2',
          hasNextBtn: true,
          hasPrevBtn: true,
          hasSkipBtn: false,
          hasCloseBtn: false,
        },
        {
          title: `${i18n.t('使用教學')} 3 / 4`,
          content: `${i18n.t('您可以透過答題進度，您可以了解測驗完成狀況、快速切換題號。')}`,
          target: 'liveToastBtn',
          hasNextBtn: true,
          hasPrevBtn: true,
          hasSkipBtn: false,
          hasCloseBtn: false,
        },
        {
          title: `${i18n.t('使用教學')} 4 / 4`,
          content: `${i18n.t('完成全部題目作答後，點選「繳交」送出測驗。')}`,
          target: 'onboarding-4',
          hasNextBtn: false,
          hasPrevBtn: true,
          hasSkipBtn: false,
          hasCloseBtn: true,
        },
      ],
      code: '',
      year: new Date().getFullYear(),
      blankFillAnswer: '',
      isBlankFill: false,
      updateSingleQuesBlankFillTimer: null as any,
    };
  },
  watch: {
    modalShow: {
      handler(newData, oldData) {
        if (newData) {
          this.isCountTime(false);
          this.tempSubmit();
        } else if (oldData && !newData) {
          this.isCountTime(true);
        }
      },
    },
    currentNum: {
      handler() {
        this.isCountTime(true);
      },
    },
    blankFillAnswer: {
      handler(newData, oldData) {
        if (this.ansSet[this.currentNum].isBlankFill) {
          this.checkIsAllSelected();
        }
      },
    },
    isBlankFill: {
      handler(newData, oldData) {
        if (!oldData && newData) {
          // add
          this.updateSingleQuesBlankFillTimer = window.setInterval(() => {
            this.ansSet[this.currentNum].blankFillAnswer = this.blankFillAnswer;
            this.checkIsAllSelected();
          }, 1000);
        } else if (!newData) {
          // remove
          window.clearInterval(this.updateSingleQuesBlankFillTimer);
        }
      },
    },
  },
  beforeCreate() {
    this.$i18n.locale = this.$route.query.language as string;
  },
  async created() {
    this.$store.commit('updateQuizCode', this.$route.query.code);
    this.code = this.$route.query.code as string;
    this.clientType = this.$route.query.clientType as string;
    let quizCodeStatus = QuizStatus.INITIAL;// -1
    this.$store.commit('updateLoading', true);
    try {
      const status = await QuizApi.check(this.$route.query.code as string);
      if (status === Status.TEMP_SUBMITTED || status === Status.STARTED || status === Status.TRY_AGAIN) {
        quizCodeStatus = QuizStatus.VALID; // The quiz code is valid.

        const onboardingBody = { answerGroupShortId: this.$route.query.code };
        const onboardingInstance = await UserApi.onboarding(onboardingBody);
        console.log(`onboardingInstance ${JSON.stringify(onboardingInstance)}`);
        if ((onboardingInstance.hasOnboarding) === true) {
          const instance = await QuizApi.create(this.$route.query.code as string) as CreateRespondModel;
          // console.log(`instance: ${JSON.stringify(instance)}`);
          this.quizData = instance.answerList;
          this.amount = instance.amount;
          const newAnswerList = instance.answerList as Array<any>;
          for (let i = 0; i < instance.amount; i += 1) {
            const {
              userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect, isBlankFill, blankFillAnswer, answerRequired,
            } = newAnswerList[i];
            newAnswerList[i] = new Question(userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect, isBlankFill, blankFillAnswer, answerRequired);
          }
          this.ansSet = newAnswerList;
          this.refreshAudio();
          this.isCountTime(true);
          this.tempSubmitTimer = window.setInterval(() => {
            if (this.blankFillAnswer !== '') {
              this.ansSet[this.currentNum].blankFillAnswer = this.blankFillAnswer;
            }
            this.tempSubmit();
          }, 10000);
          this.checkIsAllSelected();
        } else { // New user
          this.$store.commit('updateDisplayOverlay', true);

          const exampleInstance = ExampleQuizSet.example;
          this.quizData = exampleInstance.answerList;
          this.amount = exampleInstance.amount;
          const newAnswerList = exampleInstance.answerList as Array<any>;
          for (let i = 0; i < exampleInstance.amount; i += 1) {
            const {
              userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect, isBlankFill, blankFillAnswer, answerRequired,
            } = newAnswerList[i];
            newAnswerList[i] = new Question(userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect, isBlankFill, blankFillAnswer, answerRequired);
          }
          this.ansSet = newAnswerList;
          this.onboardingStatus = OnboardingStatus.INITIAL;

          this.onboardingIndex = 0;
          this.onboardingPrevIndex = 0;
        }
      } else if (status === Status.SUBMMITED) {
        quizCodeStatus = QuizStatus.SUBMMITED; // The quiz code already used - 1
      }
    } catch (error: unknown) {
      const err = error as any;
      this.errored = true;
      if (err.response.status === 404) {
        quizCodeStatus = QuizStatus.NOT_EXISTING; // The quiz code is invalid 2
      }
    } finally {
      if (quizCodeStatus !== QuizStatus.VALID) { // The quiz code is valid.
        this.$router.push({
          path: '/tip',
          query: {
            quizCodeStatus: `${quizCodeStatus}`,
            quizCode: this.code,
          },
        });
      }
      const requestNum = this.$route.query.num as unknown as number;
      if (requestNum <= this.amount) {
        this.currentNum = (requestNum - 1);
      }
      this.blankFillAnswer = this.ansSet[this.currentNum].blankFillAnswer;
      this.isBlankFill = this.ansSet[this.currentNum].isBlankFill;
      this.$store.commit('updateLoading', false);
    }
  },
  destroyed() {
    window.clearInterval(this.timer);
    window.clearInterval(this.updateSingleQuesBlankFillTimer);
  },
  methods: {
    compulsionCheck() {
      let compulsoryUnfinishedCount = 0;
      for (let i = 0; i < this.ansSet.length; i += 1) {
        if (this.quizData[i].sourceQuiz.answerRequired) {
          if (!this.checkSignleQuestion(this.ansSet[i].userAnswer)) {
            if (this.ansSet[i].isBlankFill) {
              if (this.ansSet[i].blankFillAnswer === '') {
                compulsoryUnfinishedCount += 1;
              }
            } else { compulsoryUnfinishedCount += 1; }
          }
        }
      }
      if (compulsoryUnfinishedCount === 0) {
        this.$bvModal.show('modal-scoped');
      } else {
        this.$bvToast.toast(`${this.$i18n.t('未完成所有必答題，無法繳交')}`, {
          toaster: 'b-toaster-top-center',
          title: this.$i18n.t('抱歉') as string,
          solid: true,
          appendToast: false,
          variant: 'secondary',
          autoHideDelay: 1000,
        });
      }
    },
    async setQuizSet() {
      const instance = await QuizApi.create(this.$route.query.code as string) as CreateRespondModel;
      console.log(`instance: ${JSON.stringify(instance)}`);
      this.quizData = instance.answerList;
      this.amount = instance.amount;
      const newAnswerList = instance.answerList as Array<any>;
      for (let i = 0; i < instance.amount; i += 1) {
        const {
          userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect, isBlankFill, blankFillAnswer, answerRequired,
        } = newAnswerList[i];
        newAnswerList[i] = new Question(userAnswer, sourceQuiz, uuid, timeSpent, multipleSelect, isBlankFill, blankFillAnswer, answerRequired);
      }
      this.ansSet = newAnswerList;
      this.isCountTime(true);
      this.tempSubmitTimer = window.setInterval(() => {
        if (this.blankFillAnswer !== '') {
          this.ansSet[this.currentNum].blankFillAnswer = this.blankFillAnswer;
        }
        this.tempSubmit();
      }, 10000);
      this.isBlankFill = this.ansSet[this.currentNum].isBlankFill;
      this.checkIsAllSelected();
    },
    refreshAudio() {
      if (JSON.parse(JSON.stringify(this.ansSet[this.currentNum].sourceQuiz)).enableAudio) {
        this.audioControl = false;
        setTimeout(() => {
          this.audioControl = true;
        }, 10);
      } else {
        this.audioControl = false;
      }
    },
    previousBtn() {
      if (this.currentNum - 1 > -1) {
        this.ansSet[this.currentNum].blankFillAnswer = this.blankFillAnswer;
        this.blankFillAnswer = '';
        this.currentNum -= 1;
        this.tempSubmit();
        this.androidUpdateNum();
        this.refreshAudio();
        this.blankFillAnswer = this.ansSet[this.currentNum].blankFillAnswer;
        this.isBlankFill = this.ansSet[this.currentNum].isBlankFill;
      } else if (this.allowFirstTip) {
        this.toastContent = this.$i18n.t('已經是第一題') as string;
        this.toast('b-toaster-top-center');
      }
    },
    nextBtn() {
      if (this.currentNum + 1 < this.amount) {
        this.ansSet[this.currentNum].blankFillAnswer = this.blankFillAnswer;
        this.blankFillAnswer = '';
        this.currentNum += 1;
        this.tempSubmit();
        this.androidUpdateNum();
        this.refreshAudio();
        this.blankFillAnswer = this.ansSet[this.currentNum].blankFillAnswer;
        this.isBlankFill = this.ansSet[this.currentNum].isBlankFill;
        // scrollTop start
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // scrollTop end
      } else if (this.allowLastTip) {
        this.toastContent = this.$i18n.t('已經是最後一題') as string;
        this.toast('b-toaster-top-center');
      }
    },
    listBtn() {
      this.modalShow = true;
    },
    toast(toaster: string, append = false) {
      // this.counter += 1;
      this.allowLastTip = false; this.allowFirstTip = false;
      this.$bvToast.toast(`${this.toastContent}`, {
        title: this.$i18n.t('抱歉') as string,
        toaster,
        solid: true,
        appendToast: append,
        autoHideDelay: 900,
      });
      setTimeout(() => { this.allowLastTip = true; this.allowFirstTip = true; }, 1200);
    },
    updateCurrentNum(e:number) {
      this.ansSet[this.currentNum].blankFillAnswer = this.blankFillAnswer;
      // this.blankFillAnswer = '';
      this.currentNum = e;
      this.modalShow = false;
      this.androidUpdateNum();
      this.blankFillAnswer = this.ansSet[this.currentNum].blankFillAnswer;
      this.isBlankFill = this.ansSet[this.currentNum].isBlankFill;
    },
    updateSubOfAnsSet(data:[]) {
      this.ansSet[this.currentNum].userAnswer = data;
    },
    async submitBtn() {
      console.log(`ansSet: ${JSON.stringify(this.ansSet)} `);
      const data = { ansSet: this.ansSet.map((obj) => JSON.parse(`{"uuid":"${obj.uuid}", "timeSpent":${obj.timeSpent}, "userAnswer":${JSON.stringify(obj.userAnswer)}, "blankFillAnswer":${JSON.stringify(obj.blankFillAnswer)}}`)) };
      // const data = { ansSet: setArray } as unknown;

      this.isCountTime(false);
      window.clearInterval(this.tempSubmitTimer);
      this.$store.commit('updateLoading', true);

      try {
        await QuizApi.submit(this.$route.query.code as string, data as unknown);

        this.$store.commit('updateLoading', false);

        QuizUtils.sendAnswerReport(this.$route.query.code.toString());

        this.$router.push({ path: `/submitted?clientType=${this.clientType}&quizCode=${this.code}` });
      } catch (error) {
        this.isCountTime(true);
        this.tempSubmitTimer = window.setInterval(() => {
          if (this.blankFillAnswer !== '') {
            this.ansSet[this.currentNum].blankFillAnswer = this.blankFillAnswer;
          }
          this.tempSubmit();
        }, 10000);

        LoggingApi.log(`submit error: ${error}`);
      }
    },
    tempSubmit() {
      const data = { ansSet: this.ansSet.map((obj) => JSON.parse(`{"uuid":"${obj.uuid}", "timeSpent":${obj.timeSpent}, "userAnswer":${JSON.stringify(obj.userAnswer)}, "blankFillAnswer":${JSON.stringify(obj.blankFillAnswer)}}`)) };
      try {
        QuizApi.save(this.$route.query.code as string, data as unknown);
      } catch (error) {
        console.log(error);
      }
    },
    isCountTime(data: boolean) {
      if (data) {
        window.clearInterval(this.timer);
        this.timer = null;
        this.timer = window.setInterval(this.addTime, 100);
      } else {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },
    addTime() {
      this.ansSet[this.currentNum].timeSpent += 100;
    },
    doubleClick() { // For handling double click event
      console.log('double');
    },
    guideBtn() {
      this.$store.commit('updateDisplayOverlay', true);
      this.onboardingStatus = OnboardingStatus.MANUAL;
      this.onboardingIndex = 0;
      this.onboardingPrevIndex = 0;
      this.isCountTime(false);
    },
    closeOnboardingBtn() {
      this.$store.commit('updateDisplayOverlay', false);
      if (this.onboardingStatus === OnboardingStatus.INITIAL) {
        // this.tempSubmitTimer = window.setInterval(this.tempSubmit, 10000);
        // this.checkIsAllSelected();
        this.setQuizSet();
      }
      this.onboardingStatus = OnboardingStatus.DISABLE;
      this.isCountTime(true);
    },
    onboardingNextBtn() {
      this.onboardingIndex += 1;

      setTimeout(() => {
        this.onboardingPrevIndex += 1;
      }, 100);
    },
    onboardingPreviousBtn() {
      this.onboardingIndex -= 1;

      setTimeout(() => {
        this.onboardingPrevIndex -= 1;
      }, 100);
    },
    checkIsAllSelected() {
      let unfinishedCount = 0;
      for (let i = 0; i < this.ansSet.length; i += 1) {
        if (!this.checkSignleQuestion(this.ansSet[i].userAnswer)) {
          if (this.ansSet[i].isBlankFill) {
            if (this.ansSet[i].blankFillAnswer === '') {
              unfinishedCount += 1;
            }
          } else { unfinishedCount += 1; }
        }
      }
      if (unfinishedCount === 0) {
        this.isAllSelected = true;
        if (this.allowAllSelectedTip && !this.ansSet[this.currentNum].isBlankFill) {
          this.allowAllSelectedTip = false;
          this.$bvToast.toast(`${this.$i18n.t('已答完所有題目')}`, {
            toaster: 'b-toaster-bottom-center',
            title: this.$i18n.t('完成') as string,
            solid: true,
            appendToast: false,
            autoHideDelay: 1000,
          });
          setTimeout(() => { this.allowAllSelectedTip = true; }, 1000);
        }
      } else {
        this.isAllSelected = false;
      }
    },
    checkSignleQuestion(data: any) {
      const checkResult = false;
      for (let i = 0; i < data.length; i += 1) {
        if (data[i] !== '' && data[i] !== undefined && data[i] !== null) {
          return true;
        }
      }
      return checkResult;
    },
    androidUpdateNum() {
      const u = navigator.userAgent;
      if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
        window.AndroidWebView.updateQuesNum(this.currentNum + 1);
      }
    },
  },
});
