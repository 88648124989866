


























import Vue from 'vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import QuizStatus from '@/models/QuizStatus';
// eslint-disable-next-line import/no-cycle
import QuizUtils from '@/libs/QuizUtils';

export default Vue.extend({
  components: {
    LanguageSelector,
  },
  data() {
    return {
      quizCodeStatus: -1,
      QuizStatus,
      quizCode: '',
    };
  },
  computed: {
    // quizCode(): string | null {
    //   return this.$store.state.code;
    // },
  },
  created() {
    this.quizCodeStatus = this.$route.query.quizCodeStatus as unknown as number;
    this.quizCode = this.$route.query.quizCode as string;
  },
  methods: {
    async sendResultViewer() {
      if (this.quizCode !== '') {
        const resultViewerUrl = await QuizUtils.sendAnswerReport(this.quizCode);
        window.open(resultViewerUrl, '_blank');
      }
    },
  },
});
